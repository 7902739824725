import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/Seo/seo"

import Hero from "../components/PageParts/SinglePost/Hero"
import MainLayout from "../components/PageParts/SinglePost/MainLayout"
import PostNav from "../components/PageParts/SinglePost/PostNav"
import MoreIntel from "../components/PageParts/SinglePost/MoreIntel"

const post = props => {
  const { post } = props.data
  const { pageContext } = props
  const metaDescription = post.excerpt.replace(/(<([^>]+)>)/gi, "")
  const metaImg = post.featured_media.localFile.publicURL
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={`${post.title}`}
        description={`${metaDescription}`}
        pageImg={metaImg}
        location={props.location.pathname}
      />
      <Hero img={post.featured_media.localFile.childImageSharp.fluid} />
      <MainLayout post={post} url={props.location.href} />
      <PostNav next={pageContext.next} prev={pageContext.prev} />
      <MoreIntel
        postId={post.wordpress_id}
        catId={post.categories[0].wordpress_id}
        catName={post.categories[0].name}
      />
    </Layout>
  )
}

export const query = graphql`
  query postQuery($slug: String!) {
    post: wordpressPost(slug: { eq: $slug }) {
      acf {
        _swb_author_pull_quote
      }
      date(formatString: "MMMM D, Y")
      content
      excerpt
      featured_media {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date
      }
      title
      wordpress_id
      author
      categories {
        name
        wordpress_id
      }
    }
  }
`
export default post
