import React from "react"
import BgImg from "gatsby-background-image"
import styled from "styled-components"

const HeroStyled = styled.div`
  .main-featured-image {
    width: 100%;
    height: 40rem;

    @media (min-width: 768px) {
      height: 50rem;
    }

    @media (min-width: 1025px) {
      height: 75rem;
    }
  }
`

const Hero = ({ img }) => {
  return (
    <HeroStyled>
      <BgImg className="main-featured-image" Tag="div" fluid={img} />
    </HeroStyled>
  )
}

export default Hero
