import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { MedWrapper, buttonTwo, fontSizer } from "../../../Utilities"

const PostNavStyled = styled.div`
  .wrapper {
    ${MedWrapper};
  }

  nav {
    a {
      ${buttonTwo};
      ${fontSizer(1.8, 2, 76.8, 110, 2)};
      display: block;
      margin: 2rem;
      text-align: center;

      @media (min-width: 768px) {
        display: inline-block;
      }
    }
  }
`

const PostNav = ({ next, prev }) => {
  return (
    <PostNavStyled>
      <div className="wrapper">
        <nav>
          {prev && (
            <Link to={`/blog/${prev}`}>
              Next Post
            </Link>
          )}
          <Link to="/blog">
            Blog Home
          </Link>
          {next && (
            <Link to={`/blog/${next}`}>
              Prev Post
            </Link>
          )}
        </nav>
      </div>
    </PostNavStyled>
  )
}

export default PostNav
