import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

const TeamStephanie = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          teamStephanie: file(relativePath: { eq: "team-stephanie.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Img
              fluid={data.teamStephanie.childImageSharp.fluid}
              alt="Don't know Where to start? Switchback Creative"
            />
          </>
        )
      }}
    />
  )
}

export default TeamStephanie
