import React, { Component } from "react"
import axios from "axios"
import styled from "styled-components"

import MorePostCard from "./MorePostCard"
import { MedWrapper } from "../../../Utilities"
import TitleUnderline from "../../Shared/TitleUnderline"

const MoreIntelSection = styled.section`
  .wrapper {
    ${MedWrapper};
  }

  .more-title {
    width: 100%;
    text-align: center;
  }

  .more-posts-wrapper {
    ${MedWrapper};
    width: 100%;
    margin: 0;
    padding: 0;
  }
`

class MoreIntel extends Component {
  constructor(props) {
    super(props)
    this.getMoreIntel = this.getMoreIntel.bind(this)
    this.state = {
      posts: [],
      defaultPosts: [],
    }
  }

  async getMoreIntel(reqCat, reqDef) {
    const posts = await axios.get(`${reqCat}`)
    const defaultPosts = await axios.get(`${reqDef}`)
    this.setState(prevState => {
      return {
        ...prevState,
        posts: posts.data,
        defaultPosts: defaultPosts.data,
      }
    })
  }

  componentDidMount() {
    const CATURL = `https://switchbackcreative.swbdatabases.ca/wp-json/wp/v2/posts?categories=${this.props.catId}&per_page=3&exclude=${this.props.postId}&_embed`
    const DEFAULTURL = `https://switchbackcreative.swbdatabases.ca/wp-json/wp/v2/posts?per_page=3&exclude=${this.props.postId}&_embed`
    this.getMoreIntel(CATURL, DEFAULTURL)
  }
  render() {
    const { posts, defaultPosts } = this.state
    const displayPosts = posts.length > 0 ? posts : defaultPosts
    if (displayPosts.length === 1) {
      displayPosts.push(defaultPosts[0])
      displayPosts.push(defaultPosts[1])
    } else if (displayPosts.length === 2) {
      displayPosts.push(defaultPosts[0])
    }

    return (
      <MoreIntelSection>
        <div className="wrapper">
          <div className="more-title">
            <TitleUnderline title="More Intel" />
          </div>
          {displayPosts.length > 0 && (
            <div className="more-posts-wrapper">
              {displayPosts.map(post => {
                return <MorePostCard key={post.id} post={post} />
              })}
            </div>
          )}
        </div>
      </MoreIntelSection>
    )
  }
}

export default MoreIntel
