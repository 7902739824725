import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import {
  headlineThree,
  fontSizer,
  colors,
  bodyCopy,
  buttonTwo,
} from "../../../Utilities"

const MorePostCardStyled = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 5rem;
  padding-bottom: 5rem;

  @media (min-width: 768px) {
    width: calc(50% - 6rem);
    margin: 0 3rem 2rem;
    padding-bottom: 2.5rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.3333% - 4rem);
    margin: 2rem;
    padding-bottom: 5rem;
  }

  .post-bg-img {
    width: 100%;
    min-height: 33.5rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .post-category {
    margin-bottom: 1.5rem;
    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 150, 2)}
      margin: 0;
      color: ${colors.colorSecondary};
      text-transform: uppercase;
    }
  }

  .post-title {
    margin-bottom: 2.5rem;

    h3 {
      ${headlineThree};
      ${fontSizer(2, 3, 76.8, 150, 2.2)}
      margin: 0;
      color: ${colors.colorPrimary};
      text-transform: uppercase;

      a {
        font-size: 1em;
      }
    }
  }

  .post-excerpt {
    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 150, 2)}
      color: #585858;
    }
  }

  .post-read-more {
    position: absolute;
    bottom: 0;
    left: 0;

    a {
      ${buttonTwo};
    }
  }
`

const MorePostCard = ({ post }) => {
  return (
    <MorePostCardStyled className="more-post">
      <div>
        <div
          className="post-bg-img"
          style={{
            background: `url("${post._embedded["wp:featuredmedia"][0].media_details.sizes.bloghome.source_url}")`,
          }}
        ></div>
        <div className="post-category">
          <p>{post._embedded["wp:term"][0][0].name}</p>
        </div>
        <div className="post-title">
          <h3>
            <Link
              to={`/blog/${post.slug}`}
              dangerouslySetInnerHTML={{
                __html: post.title.rendered,
              }}
            />
          </h3>
        </div>
        <div
          className="post-excerpt"
          dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
        />
        <div className="post-read-more">
          <Link to={`/blog/${post.slug}`}>Read More</Link>
        </div>
      </div>
    </MorePostCardStyled>
  )
}

export default MorePostCard
