import React from "react"
import styled from "styled-components"

import { bodyCopy, fontSizer, striptCallOut } from "../../../Utilities"
import TeamSuzy from "../../../Elements/Images/TeamSuzy"
import TeamCalla from "../../../Elements/Images/TeamCalla"
import TeamLindsay from "../../../Elements/Images/TeamLindsay"
import TeamTrevor from "../../../Elements/Images/TeamTrevor"
import TeamAfton from "../../../Elements/Images/TeamAfton"
import TeamStephanie from "../../../Elements/Images/TeamStephanie"
import TeamSwb from "../../../Elements/Images/Logos/SwitchbackCogBlack"
import SocialShareButtons from "./SocialShareButtons"

const AuthorStyled = styled.div`
  width: 100%;

  .author-pic {
    max-width: 35rem;
    margin-right: 0;
    margin-left: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 8rem;
  }

  .author-name {
    width: 100%;
    text-align: right;

    p {
      ${bodyCopy};
      color: #585858;
      text-transform: uppercase;
    }
  }

  .author-quote {
    max-width: 27rem;
    margin-top: 5rem;
    margin-right: 0;
    margin-left: auto;

    p {
      ${striptCallOut};
      ${fontSizer(2.2, 3, 76.8, 150, 2.2)};
      text-align: right;
    }
  }
`

const Author = ({ id, author, authorSlug, url, title, quote, authorid }) => {
  let authorPic = <TeamSwb />
  let authorName = "Switchback Creative Team"

  if (authorid === 3) {
    authorPic = <TeamSuzy />
    authorName = "Suzy Rounce"
  } else if (authorid === 1) {
    authorPic = <TeamTrevor />
    authorName = "Trevor Rounce"
  } else if (authorid === 6) {
    authorPic = <TeamAfton />
    authorName = "Afton Aikens"
  } else if (authorid === 7) {
    authorPic = <TeamSwb />
    authorName = "Calla Novello Lauritsen"
  } else if (authorid === 8) {
    authorPic = <TeamStephanie />
    authorName = "Stephanie Calver"
  } else if (authorid === 4) {
    authorPic = <TeamLindsay />
    authorName = "Lindsay Harle"
  } else {
    authorPic = <TeamSwb />
    authorName = "Switchback Creative Team"
  }

  return (
    <AuthorStyled>
      <div className="author-pic">{authorPic}</div>
      <div className="author-name">
        <p>{authorName}</p>
      </div>
      <div className="author-social-share">
        <SocialShareButtons url={url} title={title} />
      </div>
      {quote && (
        <div className="author-quote">
          <p>"{quote}"</p>
        </div>
      )}
    </AuthorStyled>
  )
}

export default Author
