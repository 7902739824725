import React from "react"
import styled from "styled-components"

import { headlineThree, bodyCopy, fontSizer, colors } from "../../../Utilities"

const MainContentStyled = styled.div`
  width: 100%;

  .main-content-title {
    margin-bottom: 5rem;

    h1 {
      ${headlineThree};
      ${fontSizer(2, 4, 76.8, 150, 2.2)}
      margin: 0;
      margin-bottom: 1rem;
      color: ${colors.colorPrimary};
      text-transform: uppercase;
    }

    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 150, 2)}
      margin: 0;
     
      color: #585858;
      text-transform: uppercase;
    }

    p.category-title {
      margin-bottom: 1rem;
      color: ${colors.colorSecondary};
      font-weight: 700;      
    }
  }

  .main-content-wysiwyg {
    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 150, 2)}
      color: #585858;

      strong {
        color: #000;
        font-weight: 900;        
      }
    }

    a {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 150, 2)}
      color: ${colors.colorSecondary};
      font-weight: 900;

      &:hover {
        color: ${colors.colorPrimary};
      }
    }

    ul,
    ol {
      width: 100%;
      li {
        ${bodyCopy};
        ${fontSizer(1.6, 1.8, 76.8, 150, 2)}
        display: block;
        margin-bottom: 1.5rem;
        color: #585858;
      }
    }
  }
`

const MainContent = ({ title, date, cat, content }) => {
  return (
    <MainContentStyled>
      <div className="main-content-title">
        <h1 dangerouslySetInnerHTML={{ __html: title }} />
        <p>{date}</p>
        <p className="category-title">{cat}</p>
      </div>
      <div
        className="main-content-wysiwyg"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </MainContentStyled>
  )
}

export default MainContent
