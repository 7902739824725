import React from "react"
import styled from "styled-components"

import MainContent from "./MainContent"
import Author from "./Author"

import { MedWrapper } from "../../../Utilities"

const MainLayoutStyled = styled.article`
  width: 100%;

  .article-wrapper {
    ${MedWrapper};
    flex-direction: column-reverse;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .article-meta {
    width: 100%;

    @media (min-width: 1025px) {
      width: calc(33.33%);
    }
  }

  .article-content {
    width: 100%;

    @media (min-width: 1025px) {
      width: calc(66.66% - 3rem);
      margin-left: 3rem;
    }
  }
`

const MainLayout = props => {
  const { post, url } = props
  return (
    <MainLayoutStyled>
      <div className="article-wrapper">
        <div className="article-meta">
          <Author
            author={`srounce`}
            authorSlug={`srounce`}
            id={post.wordpress_id}
            url={url}
            title={post.title}
            quote={post.acf._swb_author_pull_quote}
            authorid={post.author}
          />
        </div>
        <div className="article-content">
          <MainContent
            title={post.title}
            date={post.date}
            cat={post.categories[0].name}
            content={post.content}
          />
        </div>
      </div>
    </MainLayoutStyled>
  )
}

export default MainLayout
